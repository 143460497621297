import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import * as Sentry from '@sentry/browser'

import { useAuthStore } from '@/stores/auth'
import { errors } from '@/data/errors'

type RequestConfig = AxiosRequestConfig & {
  _retry: boolean
}

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
})

instance.interceptors.request.use(
  async (config) => {
    const authStore = useAuthStore()
    const accessToken = authStore.access_token
    if (accessToken && config?.headers && !config.skipAuthHeader) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  async (response) => {
    const authStore = useAuthStore()
    convertAxiosToFetchResponse(response)

    const data = response?.data
    const errors = data?.errors
    const originalRequest = response.config as RequestConfig

    if (errors) {
      if (authStore.shouldRefreshToken(response) && !originalRequest?._retry) {
        const tokens = await authStore.refreshTokens()
        if (!tokens) {
          throw errors
        }

        originalRequest._retry = true

        return instance(originalRequest)
      }

      SetResponseOk(response, false)
      throw errors
    }

    return response
  },
  async (error) => {
    const authStore = useAuthStore()
    const originalRequest = error.response?.config as RequestConfig
    let backendMessages: string[] = []

    if (authStore.shouldRefreshToken(error.response)) {
      if (originalRequest?._retry) {
        return authStore.logout()
      }

      try {
        const token = await authStore.refreshTokens()
        if (token) {
          originalRequest._retry = true

          return instance(originalRequest)
        }
      } catch (error) {
        return authStore.logout()
      }
    }

    if (error?.response?.status === 401) {
      return authStore.logout()
    }

    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      if (error.response && error.response.data) {
        backendMessages = Array.isArray(error.response.data)
          ? error.response.data
          : Object.keys(error.response.data).length
          ? Object.values(error.response.data).flat()
          : []

        const userFriendlyMessages = backendMessages.map((message: string) => {
          return (
            errors[message] || 'Ocorreu um erro inesperado, tente novamente.'
          )
        })

        if (userFriendlyMessages.length) {
          return Promise.reject(userFriendlyMessages)
        }

        return Promise.reject(error)
      }

      return Promise.reject(error)
    }

    Sentry.captureException(error)
    return Promise.reject(error)
  },
)

const convertAxiosToFetchResponse = (response: AxiosResponse) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  response.headers['forEach'] = function (callback: any) {
    for (const header in this) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (Object.hasOwn(this, header)) {
        callback(this[header], header, this)
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  response['text'] = async function () {
    return JSON.stringify(this.data)
  }
  SetResponseOk(response, true)
}

const SetResponseOk = (response: AxiosResponse, ok: boolean) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  response['ok'] = ok
}

export default instance
